
import { mapWritableState } from 'pinia';
import { defineComponent } from 'vue';
import { useMainStore } from '@/store/mainStore';

export default defineComponent({
  computed: mapWritableState(useMainStore, ['loading']),
  mounted() {
    this.loading = false;
  },
  props: {
    status: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
});
